import styled from 'styled-components';
import theme from '../../theme';

export const ImgWrapper = styled.div`
	padding: 40px 5% 30px 5%;
	width: ${props => props.fullWidth ? 90 : 50}%;
	max-width: 500px;
    min-width: 200px;
    position: relative;
	
	&:hover {
		& > div {
			transform: rotate(0deg);
		}

		& > div > div > img {
			transform: rotate(${props => (props.invert ? '' : '-')}6deg);
        }

		iframe {
			transform: rotate(${props => (!props.invert ? '-' : '')}0deg);
		}
	}

	@media (max-width: 920px) {
		width: 70%;
		order: 1;
	}

	& iframe {
		transform: rotate(${props => (props.invert ? '-' : '')}6deg);
		transition: 0.3s;
	}
`;

export const ImgBackdrop = styled.div`
	width: 100%;
	transform: rotate(${props => (props.invert ? '' : '-')}6deg);
	background: #D8D8D8;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	transition: 0.3s;
`;

export const Img = styled.img`
	width: 100%;
	transform: rotate(${props => (props.invert ? '-' : '')}6deg);
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	transition: 0.3s;
	background-color white;
`;

export const Loading = styled(theme.animation.div)`
	width: 100%;
	transform: rotate(${props => (props.invert ? '-' : '')}6deg);
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	transition: 0.3s;
	min-height: 20px;
`;

export const TextWrapper = styled.div`
	width: ${props => (props.image === false ? 'calc(90% - 40px)' : '50%')};
	padding: ${props => (!props.tall ? '15px 5%' : '5%')};
	font-size: 1.3em;
	margin: ${props => (props.image === false ? '0 20px' : '20px')};
	color: ${props => (props.light ? theme.text('light') : theme.text('dark'))};
	${props => (props.light ? `
		background-color: rgba(0, 0, 0, 0.8);
		// text-shadow: 1px 1px 1px darkgrey;
	` : '')}

	& a {
        text-decoration-style: dotted;
		color: ${theme.color('darkBlue')};
	}
	
	@media (max-width: 920px) {
		width: 70%;
		order: 2;
	}

    li {
        word-break: break-word;
    }
`;

export const Description = styled.div`
    z-index: 999;
    color: grey;
    padding-top: 30px;
`;
