import React from 'react';
import moment from 'moment';
import ImageLoader from 'react-load-image';
import { Wrapper, Image, Body, Title, Description, URL, PublicationDate, Loader } from './components';
import { noImage } from '../../assets';

export default (props) => {
	const { article, imageOverride } = props;
	let image = noImage;
	let dateMoment = moment();

	// lowest priority - og property
	if (article.image) {
		image = require(`../../crawledAssets/${article.image}`);
	}

	// next priority - prop passed to component
	if (imageOverride) {
		image = imageOverride;
	}
	
	// higest priority - image override from contentful
	if (article.imageOverride) {
		image = article.imageOverride;
	}

	if (article.publicationDate) {
		dateMoment = moment(article.publicationDate);
	}
	return (
		<Wrapper href={article.url} target="_blank" rel="noopener noreferrer">
			<ImageLoader src={image}>
				<Image />
				<Loader />
				<Loader />
			</ImageLoader>
			<Body>
				<Title>
					{article.title}
					<PublicationDate>
						{dateMoment.format('MMMM Do, YYYY')}
					</PublicationDate>
				</Title>
				<Description>{article.descriptionOverride || article.description}</Description>
				<URL>{article.url}</URL>
			</Body>
		</Wrapper>
	);
}