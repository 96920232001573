import React, { Component } from 'react';
import { HTMLButton } from './components';

export default class Button extends Component {
	render() {
		return (
            <HTMLButton 
                onClick={this.props.onClick} 
                color={this.props.color}
                fontSize={this.props.fontSize}
            >
                {this.props.label}
            </HTMLButton>
		);
	}
}
