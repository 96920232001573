import React, { useState } from 'react';
import { Wrapper, Text, Image, Title, ViewMoreLink } from './components';
import { formatHTML } from '../../utils/content';


export default (props) => {
	const [expanded, setExpanded] = useState(false);

	return (
		<Wrapper>
			<Image src={props.data.image.url} />
			<Title>
				{props.data.fullName}
			</Title>
			<Text>
				<em>
					{formatHTML(!expanded ? props.data.shortMarkup : props.data.markup)}
				</em>
			</Text>
			{
				props.data.truncated ? (
					<ViewMoreLink onClick={() => setExpanded(!expanded)}>
						view {!expanded ? 'more' : 'less'}
					</ViewMoreLink>
				) : null
			}

		</Wrapper>
	)
}
