import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Link)`
	@keyframes Gradient {
		0% {
			background-position: 0% 50%
		}
		50% {
			background-position: 100% 50%
		}
		100% {
			background-position: 0% 50%
		}
	}

	width: calc(100% / 3);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border: 8px solid white;
	box-sizing: border-box;

	@media (max-width: 920px) {
			font-size: 0.9em;
		}

		@media (max-width: 500px) {
			font-size: 0.7em;
		}

	${props => (props.color === 1 ? `
		background: linear-gradient( rgba(112,168,68,1), rgba(112,168,68,0.7)), url(${props.image});
	` : `
		background: linear-gradient( rgba(70,93,141,0.9), rgba(70,93,141,0.4)), url(${props.image});
	`)}

	background-position: left;
	background-size: cover;
	
	& svg {
		font-size: 4em;
		transform: scale(1);
		transition: 0.3s;

		@media (max-width: 920px) {
			font-size: 3em;
		}

		@media (max-width: 500px) {
			font-size: 2em;
		}
	}

	&:hover {
		animation: Gradient 10s ease infinite;
		& svg {
			transform: scale(1.2);
		}
	}

	${props => (props.fullwidth ? `
		width: 100%;
		padding: 10px 0;

		& svg {
			font-size: 3em;
		}
	` : `
		&:after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}
	`)}


`;

export const Squares = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 1200px;
	width: 100%;
	margin: 10px auto;
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); */

`;

export const Title = styled.div`
	  font-size: 1.5em;
	  padding-top: 5px;
	  padding: 10px;

	  @media (max-width: 640px) {
		font-size: 1.2em;
	}
`;

export const TileContent = styled.div`
	  text-align: center;
`;

export const Description = styled.div`
	font-size: 1.4em;
	color: #172E15;
	text-align: center;
	padding: 20px 10px 10px 10px;
	max-width: 1200px;
	margin: 0 auto;
`;