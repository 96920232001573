import colors from "./colors";
import text from "./text";
import animation from './animation';

const shadowColor = 'rgba(0,0,0,0.50)';
const defaults = {
	shadow: `0 2px 4px 0 ${shadowColor}`,
	shadowColor,
	borderColor: '#000000',
}

export default {
	color: (color, lighten) => colors(color, lighten),
	text: (color) => text(color),
	animation,
	defaults,
}