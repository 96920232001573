import React, { Component } from 'react';
import Axios from 'axios';
import { HorizontalBar, Pie, Bar } from 'react-chartjs-2';
import theme from '../../theme';
import {
	Top,
	SignUpInfo,
	SignUpText,
	QuickLinksContainer,
	QuickLinksHeader, 
	QuickLink, 
	Link, 
	QuickLinksFooter, 
	Body,
	SectionTitle,
	ChartWrapper,
	StatusWrapper,
	ChartSection,
	Field,
	Submit,
	FieldWrapper,
	QuickLinksWrapper,
} from './components';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faKey,
	faCalendarAlt,
	faBook,
	faSpinner,
	faArrowRight,
	faEnvelope,
	faTree,
	faMicrochip,
} from '@fortawesome/free-solid-svg-icons';
import { volunteers, stripes } from '../../data.json';
import { formatHTML, getMetadata } from '../../utils/content';
import Cycler from './cycler';
import ImageStack from '../../components/ImageStack';
import ContentLoop from '../../components/ContentLoop';

export default class Volunteers extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			data: null,
			error: false,
			code: '',
			validCode: null,
			loadingPersonal: false,
			errorPersonal: false,
			dataPersonal: null,

		}
	}
	async fetchData() {
		const res = await Axios.get('topTen');
		if (res && res.status === 200 && res.data) {
			this.setState({ loading: false, error: false, data: res.data });
		} else {
			this.setState({ loading: false, error: true, data: null });
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	async handleFormSubmit(e) {
		e.preventDefault();
		const { code, validCode } = this.state;
		if (validCode) {
			// Reset state when submitting again
			this.setState({ loadingPersonal: true, errorPersonal: false, dataPersonal: null });
			const res = await Axios.post('lookUp', { data: code });
			if (res && res.status === 200 && res.data) {
				this.setState({ loadingPersonal: false, errorPersonal: false, dataPersonal: res.data });
			} else {
				this.setState({ loadingPersonal: false, errorPersonal: true, dataPersonal: null });
			}
		}
	}

	render() {
		const { loading, error, data, loadingPersonal, errorPersonal, dataPersonal } = this.state;
		const volunteerChartData = ((data && data.volunteers) || []).map(record => record.hours);
		const volunteerChartLabels = ((data && data.volunteers) || []).map((record, i) => `#${i + 1} (${record.name})`);
		const rawTypeData = (data && data.types) || {};
		
		// Keep pie chart data in descending order so the darker shades are the
		// larger parts of the chart
		const typeChartData = Object.keys(rawTypeData)
			.map(key => ({ type: key, hours: rawTypeData[key].hours }))
			.sort((a, b) => a.hours - b.hours);
        console.log(typeChartData)
		const pieColors = ['#762a83','#af8dc3','#e7d4e8','#ffffbf','#d9f0d3','#7fbf7b','#1b7837'];
		// const interval = 100 / typeChartData.length;
		// for (let i = 0; i < typeChartData.length; i++) {
		// 	pieColors.push(`rgba(64, 90, 20, ${(i + 1) * interval / 100})`);
		// }
		let userChartData = {
			userValues: [],
			overallMeans: [],
			labels: [],
			overallMax: [],
		};
		if (data && data.countsByType && dataPersonal && dataPersonal.typeBreakdown) {
			const breakdown = dataPersonal.typeBreakdown;
			const types = data.countsByType;
			types.forEach((r, i) => {
				userChartData.labels.push(r.type);
				userChartData.overallMeans.push(r.hours / r.count);
				userChartData.overallMax.push(r.maxHours);
				const search = breakdown.findIndex(b => b.type === r.type);
				if (search >= 0) {
					// found the record
					userChartData.userValues.push(breakdown[search].hours);
				} else {
					userChartData.userValues.push(0);
				}
			});
		}
		return (
			<React.Fragment>
				<Header title="Volunteers"/>
					<Top>
						<SignUpInfo color="white">
							<SignUpText>
								{
									stripes.bypage.volunteers && stripes.bypage.volunteers.length
										? formatHTML(stripes.bypage.volunteers[0].markup)
										: null
								}
								{
									stripes.bypage.volunteers && stripes.bypage.volunteers.length
										&& stripes.bypage.volunteers[0].media && stripes.bypage.volunteers[0].media.url
											&& <ImageStack src={stripes.bypage.volunteers[0].media.url} alt={stripes.bypage.volunteers[0].media.description} fullWidth/>
								}						
							</SignUpText>
						</SignUpInfo>
						<QuickLinksContainer>
							<QuickLinksWrapper>
								<QuickLinksHeader>
									Quick Links
								</QuickLinksHeader>
								<QuickLink>
									<FontAwesomeIcon icon={faKey} />
									<Link href={getMetadata('arbimon_login')} target="_blank" rel="noopener noreferrer"> Arbimon Login </Link>
								</QuickLink>
								<QuickLink>
									<FontAwesomeIcon icon={faCalendarAlt} />
									<Link href={getMetadata('field_calendar')} target="_blank" rel="noopener noreferrer"> Event Calendar </Link>
								</QuickLink>
								<QuickLink>
									<FontAwesomeIcon icon={faEnvelope} />
									<Link href={getMetadata('newsletter')} target="_blank" rel="noopener noreferrer"> Newsletter Signup </Link>
								</QuickLink>
								<QuickLink>
									<FontAwesomeIcon icon={faTree} />
									<Link href={getMetadata('volunteer_signup')} target="_blank" rel="noopener noreferrer"> Volunteer - Field Deployments / Tagging </Link>
								</QuickLink>
								<QuickLink>
									<FontAwesomeIcon icon={faMicrochip} />
									<Link href={getMetadata('host_signup')} target="_blank" rel="noopener noreferrer"> Volunteer - Host Recorder </Link>
								</QuickLink>
								<QuickLink>
									<FontAwesomeIcon icon={faBook} />
									<Link href="/volunteers/materials" target="_blank" rel="noopener noreferrer"> Training Materials </Link>
								</QuickLink>
								<QuickLinksFooter />
							</QuickLinksWrapper>
						</QuickLinksContainer>
					</Top>
					<Body>
						<Button
							color={'darkgreen'}
							label={'Sign Up to Volunteer'}
							fontSize={1.15}
							onClick={() => {
								window.open(getMetadata('volunteer_signup'), '_blank');
							}}
						/>
						<br />
						{
							loading ? (
								<React.Fragment>
									<StatusWrapper>
										<FontAwesomeIcon icon={faSpinner} spin />
									</StatusWrapper>
								</React.Fragment>
							) : error ? (
								<React.Fragment>
									<StatusWrapper>
										Sorry, an error occurred while fetching the data
									</StatusWrapper>
								</React.Fragment>
							) : (
								<React.Fragment>
									<ChartSection>
										<SectionTitle>Top 10 Volunteers</SectionTitle>
										<ChartWrapper>
											<HorizontalBar 
												options={{
													maintainAspectRatio: false,
													legend: {
														display: false,
													},
													scales: {
														xAxes: [{
															scaleLabel: {
																display: true,
																labelString: 'Total Hours',
															}
														}]
													}
												}}
												data={{
													labels: volunteerChartLabels,
													datasets: [{
														label: 'total hours',
														data: volunteerChartData,
														backgroundColor: 'hsl(83.10000000000002,63.6%,21.6%)',
														borderColor: 'rgb(95, 135, 31)',
														hoverBackgroundColor: 'rgb(95, 135, 31)',
													}],
												}}
											/>
										</ChartWrapper>
									</ChartSection>
									<ChartSection>
										<SectionTitle>Type of Work</SectionTitle>
										<ChartWrapper>
											<Pie
												options={{ maintainAspectRatio: false }}
												data={{
													labels: typeChartData.map((e) => e.type),
													datasets: [{
														data: typeChartData.map((e) => e.hours),
														backgroundColor: pieColors,
													}]
												}}
											/>
										</ChartWrapper>
									</ChartSection>
									<br />
									<br />
									<SectionTitle>How You Compare</SectionTitle>
									<br />
									Enter your unique ID below to see your personal 
									stats and how you stack up against other citizen scientists
									<br />
									<br />
									<FieldWrapper>
										<form onSubmit={(e) => this.handleFormSubmit(e)}>
											<Field
												placeholder="5-digit code"
												value={this.state.code}
												onChange={(e) => {
													const { value } = e.target;
													this.setState({ code: value });
												}}
												borderColor={
													this.state.validCode === true
														? 'green'
														: this.state.validCode === false
															? 'red'
															: 'grey'
												}
												onFocus={() => {
													this.setState({ validCode: null });
												}}
												onBlur={() => {
													const { code } = this.state;
													if (code.length === 0) {
														this.setState({ validCode: null });
													} else {
														this.setState({ validCode: code.length === 5 });
													}
												}}
											/>
										</form>
										{
											this.state.validCode || this.state.code.length === 5 ? (
												<React.Fragment>
													<Submit onClick={(e) => this.handleFormSubmit(e)}>Submit&nbsp;<FontAwesomeIcon icon={faArrowRight} /></Submit>
												</React.Fragment>
											) : null
										}
									</FieldWrapper>
									<br />
									{
										loadingPersonal ? (
											<React.Fragment>
												<StatusWrapper>
													<FontAwesomeIcon icon={faSpinner} spin />
												</StatusWrapper>
											</React.Fragment>
										) : errorPersonal ? (
											<React.Fragment>
												<StatusWrapper>
													Sorry, an error occurred while fetching your data
												</StatusWrapper>
											</React.Fragment>
										) : dataPersonal ? (
											<React.Fragment>
												{
													dataPersonal.typeBreakdown && dataPersonal.typeBreakdown.length ? (
														<ChartSection full>
															<ChartWrapper>
																<Bar 
																	options={{ maintainAspectRatio: false }}
																	data={{
																		labels: userChartData.labels,
																		datasets: [{
																			label: 'you',
																			data: userChartData.userValues,
																			backgroundColor: theme.color('darkblue'),
																			borderColor: theme.color('lightblue'),
																			hoverBackgroundColor: theme.color('lightblue'),
																		}, {
																			label: 'average',
																			data: userChartData.overallMeans,
																			backgroundColor: 'hsl(83.10000000000002,63.6%,21.6%)',
																			borderColor: 'rgb(95, 135, 31)',
																			hoverBackgroundColor: 'rgb(95, 135, 31)',
																		}, {
																			label: 'maximum',
																			data: userChartData.overallMax,
																			backgroundColor: theme.color('orange'),
																			borderColor: theme.color('lightorange'),
																			hoverBackgroundColor: theme.color('lightorange'),
																		}],
																	}}
																/>
															</ChartWrapper>
														</ChartSection>
													) : (
														<StatusWrapper>
															No data was found using that code
															<br />
															Remember, codes are case-sensitive
														</StatusWrapper>
													)
												}
											</React.Fragment>
										) : null
									}
								</React.Fragment>
							)
						}
						<br />
						<ContentLoop content={stripes.bypage.volunteers && stripes.bypage.volunteers.length > 1 ? stripes.bypage.volunteers.slice(1, stripes.bypage.volunteers.length - 1) : []} />
						{
							volunteers.spotlight && volunteers.spotlight.length ? (
								<React.Fragment>
									<Cycler spotlight={volunteers.spotlight} />
								</React.Fragment>
							) : null
						}
						<ContentLoop invert content={stripes.bypage.volunteers && stripes.bypage.volunteers.length > 1 ? stripes.bypage.volunteers.slice(stripes.bypage.volunteers.length - 1) : []} />
					</Body>
				<Footer />
			</React.Fragment>
		);
	}
}
