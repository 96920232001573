import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	background: #B1A571;
	margin-top: auto;
`;

export const Links = styled.div`
	color: #172E15;
	text-align: center;
	padding: 15px 0;

	& a {
		color: #172E15;
		text-decoration-style: dotted;
	}
`;

