import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Gallery from '../../components/Gallery';
import { stripes, galleries } from '../../data.json';
import ContentLoop from '../../components/ContentLoop';

export default class Soundscapes extends Component {
	render() {
		return (
			<React.Fragment>
				<Header title="Soundscape Analysis" />
				<ContentLoop content={stripes.bypage.soundscapes} />
				{
					// In the case all soundscapes page content is deleted in contentful, 
					// the gallery should not show up at all
					galleries.bypage.soundscapes && galleries.bypage.soundscapes.length ? (
						galleries.bypage.soundscapes.map((gallery, index) => {
							return <Gallery key={`gallery-soundscapes-${index}`} data={gallery}/>
						})
					) : null
				}
				<Footer />
			</React.Fragment>
		);
	}
}