import React from 'react';
import { formatHTML } from '../../utils/content';
import ImageStack, { Text } from '../../components/ImageStack';
import { Stripe } from '../../components/general';

export default (props) => {
    return (
        props.content && props.content.length ? (
            props.content.map((stripe, index) => {
                const { media, markup, youtubeUrl } = stripe;
                return (
                    <Stripe color="white" key={markup} short={!(media || youtubeUrl)}>
                        {props.invert ? (
                            <>
                                {/* Text followed by image on even-indexed rows */}
                                {index % 2 || (!media && !youtubeUrl) ? null : <ImageStack embed={youtubeUrl} src={media && media.url} alt={media && media.description} />}
                                <Text image={!!(media || youtubeUrl)}>
                                    {formatHTML(markup)}
                                </Text>
                                {/* Image followed by text on odd-indexed rows */}
                                {index % 2 && (media || youtubeUrl) ? <ImageStack embed={youtubeUrl} invert src={media && media.url} alt={media && media.description}/> : null}
                            </>
                        ) : (
                            <>
                                {/* Image followed by text on odd-indexed rows */}
                                {index % 2 && (media || youtubeUrl) ? <ImageStack embed={youtubeUrl} invert src={media && media.url} alt={media && media.description}/> : null}
                                <Text image={!!(media || youtubeUrl)}>
                                    {formatHTML(markup)}
                                </Text>
                                {/* Text followed by image on even-indexed rows */}
                                {index % 2 || (!media && !youtubeUrl) ? null : <ImageStack embed={youtubeUrl} src={media && media.url} alt={media && media.description} />}
                            </>
                        )}
                    </Stripe>
                )
            })
        ) : null
    )
}