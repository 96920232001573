import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
	display: flex;
	padding: 10px;
	min-height: 250px;
	align-items: center;
	width: calc(100% - 20px);

	@media (max-width: 840px) {
		flex-direction: column;
		padding: 0;
	}
`;

export const Image = styled.img`
	border-radius: 80px;
	height: 80px;
	min-width: 80px;
	border: 2px solid grey;
`;

export const Content = styled.div`
	margin-left: 30px;
	font-size: 1.1em;

	@media (max-width: 840px) {
		margin: 0;
	}
`;

export const Handle = styled.div`
	color: black;
	font-size: 1.2em;
	padding: 2px;
`;

export const Body = styled.div`
	padding: 6px 2px;
	border-bottom: 1px solid lightgrey;
	display: flex;
	font-weight: 400;


	& a {
		color: ${theme.color('darkBlue')};
		text-decoration-style: dotted;
	}
`;

export const CreatedTime = styled.div`
	color: grey;
	font-size: 0.75em;
	width: 100%;
	text-align: right;
`;

export const Retweet = styled.div`
	color: grey;
`;

export const CyclerWrapper = styled.div`
	display: flex;
	width: calc(100% - 20px);
	max-width: 1000px;
	margin: 0 auto;
	padding: 10px;

	@media (max-width: 840px) {
		flex-direction: column;
		padding: 0;
	}
`;

export const CyclerContent = styled.div`
	width: calc(100% - 40px);
	padding: 20px;
`;

export const Arrow = styled.div`
	min-width: 80px;
	display: flex;
	align-items: center;
	font-size: 2em;
	color: lightgrey;

	& svg {
		margin: 0 auto;
		cursor: pointer;
		color: lightgrey;
		transition: 0.5s;
		transform: scale(1);
	}

	& svg:hover {
		color: grey;
		transform: scale(1.2);
	}

	@media (max-width: 840px) {
		display: none;
	}
`;

export const Positions = styled.div`
	text-align: center;
`;

export const Dots = styled.div`
	display: inline-flex;
	text-align: center;
	@media (max-width: 840px) {
		padding-top: 20px;
	}
`;

export const Dot = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 20px;
	margin: 0 6px;
	transition: 0.5s;
	cursor: pointer;
	${props => (props.active ? `
		background-color: grey;
	` : `
		background-color: lightgrey;
	`)}
`;

export const MediaWrapper = styled.a`
	height: 200px;
	min-width: 200px;
	width: 200px;
	margin-left: 20px;

	@media (max-width: 840px) {
		padding-top: 20px;
	}

	&:hover {
		& .background {
			transform: rotate(0deg);
		}

		& .image {
			transform: rotate(-6deg);
		}
	}
`;

export const MediaBackground = styled.div`
	width: 100%;
	height: 100%;
	transform: rotate(-6deg);
    background-color: #D8D8D8;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	transition: 0.3s;
`;

export const Media = styled.div`
	width: 100%;
	height: 100%;
	transform: rotate(6deg);
	background: url(${props => props.src});
	background-size: cover;
	cursor: pointer;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	transition: transform 0.3s;
`;
