import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
    Wrapper,
    SpotlightWrapper,
	SpotlightImageWrapper,
	SpotlightImage,
	SpotlightContent,
	FullName,
	Dates,
    Description,
    CyclerContent,
    Arrow,
} from './components';
import {
    SectionTitle,
} from '../components';
import { formatHTML } from '../../../utils/content';


export default class VolunteerCycler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
        };
    }

    moveLeft() {
        this.setState((prevState) => ({ index: prevState.index + 1 }));
    }

    moveRight() {
        this.setState((prevState) => ({ index: prevState.index - 1 }));
    }

    render() {
        const { spotlight } = this.props;
        const { index } = this.state;
        const volunteer = spotlight[index || 0];
        return (
            <React.Fragment>
                <SectionTitle>Volunteer Spotlight</SectionTitle>
                <Wrapper>
                    <Arrow>
                        {
                            index !== spotlight.length - 1 ? (
                                <FontAwesomeIcon icon={faChevronLeft} onClick={() => this.moveLeft()}/>
                            ) : null
                        }
                    </Arrow>
                    <CyclerContent>
                        <SpotlightWrapper key={`${volunteer.image.url}-${volunteer.month}-${volunteer.fullName}`}>
                            <SpotlightImageWrapper>
                                <SpotlightImage src={volunteer.image.url} alt={volunteer.image.title}/>
                            </SpotlightImageWrapper>
                            <SpotlightContent>
                                <FullName>{volunteer.fullName}</FullName>
                                <Dates>{volunteer.month} {volunteer.year} &nbsp;|&nbsp; {volunteer.hours} hours</Dates>
                                <Description>
                                    {formatHTML(volunteer.markup)}
                                </Description>
                            </SpotlightContent>
                        </SpotlightWrapper>
                    </CyclerContent>
                    <Arrow>
                        {
                            index !== 0 ? (
                                <FontAwesomeIcon icon={faChevronRight} onClick={() => this.moveRight()}/>
                            ) : null
                        }
                    </Arrow>
                </Wrapper>
            </React.Fragment>
        );
    }
}