import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Wrapper, Squares, Title, TileContent, Description } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faBook, faCrow, faWifi, faMap, faUsers, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { stripes, media } from '../../data.json';
import { formatHTML } from '../../utils/content';
import Partners from '../../components/Partners';

const tiles = [
	media.project_overview,
	media.mission,
	media.why_biodiversity,
	media.soundscape_analysis,
	media.species_distribution_modeling,
	media.our_team,
	media.in_the_news,
];

const aboutTiles = [{
	title: 'Project Overview',
	icon: faInfoCircle,
	route: '/about/overview',
}, {
	title: 'Mission',
	icon: faBook,
	route: '/about/mission',
}, {
	title: 'Why Biodiversity?',
	icon: faCrow,
	route: '/about/biodiversity',
}, {
	title: 'Soundscape Analysis',
	icon: faWifi,
	route: '/about/soundscapes',
}, {
	title: 'Species Distribution Modeling',
	icon: faMap,
	route: '/about/sdm',
}, {
	title: 'Our Team',
	icon: faUsers,
	route: '/about/team',
}];

export default class AboutTheProject extends Component {
	render() {
		return (
			<React.Fragment>
				<Header title="About the Project"/>
				{stripes.bypage.about && stripes.bypage.about.length ? (
					<Description>
						{formatHTML(stripes.bypage.about[0].markup)}
					</Description>
				) : null}
				<Squares>
					{
						aboutTiles.map((tile, index) => {
							return (
								<Wrapper color={index % 2 ? 2 : 1} image={tiles[index]} to={tile.route} key={tile.route}>
									<TileContent>
										<FontAwesomeIcon icon={tile.icon} />
										<Title>{tile.title}</Title>
									</TileContent>
								</Wrapper>
							);
						})
					}
					<Wrapper fullwidth="true" image={tiles[6]} color={1} to={'/about/news'}>
						<TileContent>
							<FontAwesomeIcon icon={faFileAlt} />
							<Title>In the News</Title>
						</TileContent>
					</Wrapper>
				</Squares>
				<Partners showHeader/>
				<br />
				<Footer />
			</React.Fragment>
		);
	}
}
