import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.a`
	background: #FFFFFF;
	margin: 20px;
	display: flex;
	min-height: 150px;
	max-width: 1000px;
	width: 90%;
	margin: 10px auto;
	text-decoration: none;

	@media (max-width: 840px) {
		flex-direction: column;
	}

	& .imageloader {
		min-width: 30%;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
		border: 5px solid white;
		box-sizing: border-box;

		@media (max-width: 840px) {
			min-height: 200px;
			margin-bottom: 20px;
		}
	}
`;

export const Image = styled.div`
	background: url(${props => props.src});
	background-position: center;
	background-size: cover;
	height: 100%;

	@media (max-width: 840px) {
		min-height: 200px;
	}
`;


export const Loader = styled(theme.animation.div)`
	height: 100%;
	box-sizing: border-box;

	@media (max-width: 840px) {
		min-height: 200px;
	}
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	margin-left: 20px;

	@media (max-width: 840px) {
		margin-left: 0;
		width: 100%;
	}
`;

export const Title = styled.div`
	color: #000000;
	font-size: 1.5em;
	padding: 10px 20px;
`;

export const Description = styled.div`
	color: #000000;
	padding: 0 20px;
	margin: 0 0 10px 0;
`;

export const URL = styled.div`
	color: grey;
	padding: 0 0 10px 20px;
	font-size: 0.9em;
	margin: auto 10px 10px 0;

	word-wrap: break-word;
`;

export const PublicationDate = styled.small`
	display: block;
	font-size: 0.6em;
	color: grey;
`;
