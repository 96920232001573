import styled from 'styled-components';
import theme from '../../theme';

// Top wrapper for sign up bottom and login links
export const Top = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1300px;
    width: calc(100% - 65px);
    margin: 30px auto 0 auto;
    padding: 0 25px 10px 35px;

    @media (max-width: 920px) {
        flex-direction: column;
    }

    & a {
        color: ${theme.color('darkblue')};
        text-decoration-style: dotted;
    }

    & a:hover {
        color: ${theme.color('darkgreen')};
    }
`;

export const Body = styled.div`
    max-width: 1300px;
    width: calc(100% - 65px);
    margin: 30px auto 0 auto;
    text-align: left;
    font-size: 1.1em;
    padding: 0 25px 10px 35px;

    & button {
        margin-left: calc(40px + 2.5%);
    }
`;

export const SectionTitle = styled.span`
    font-size: 1.7em;
`;

export const SignUpInfo = styled.div`
    background-color: ${props => props.color || '#B0C694'};
    background-size: cover;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;

    @media (max-width: 920px) {
        flex-direction: column;
        order: 2;
        justify-content: center;
        align-content: center;
         & > * {
             margin: 0 auto;
         }
    }
`;

export const SignUpText = styled.div`
    height: auto;
    padding-bottom: 10px;
    font-size: 0.9em;
    color: #172E15;
    text-align: left;

    @media (max-width: 920px) {
        text-align: center;
    }
`;

export const QuickLinksContainer = styled.div`
    min-width: 300px;
    margin-left: 25px;
    border-radius: 3px;
    
    @media (max-width: 920px) {
        order: 1;
        margin-bottom: 20px;
        margin-left: 0;

	}
`;

export const QuickLinksWrapper = styled.div`
    background-color: lightgrey;
    border: 1px solid black;
    box-shadow: ${theme.defaults.shadow};
    border-radius: 3px;
`;

export const QuickLinksHeader = styled.div`
    margin: 10px;
    font-weight: 700;
`;

export const QuickLinksFooter = styled.div`
    height: 20px;
`;

export const QuickLink = styled.div`
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:nth-child(2) {
        border-top: 1px solid ${theme.defaults.borderColor};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.defaults.borderColor};
    }
    
    &:hover {
        color: ${props => (theme.color('darkgreen'))};
    }

`;

export const Link = styled.a`
    padding-left: 10px;
    text-decoration: none;
`;

export const ChartWrapper = styled.div`
    width: 100%;
    padding-top: 10px;
    height: 300px;

    @media (max-width: 920px) {
        width: calc(100% - 10px);
    }
`;

export const ChartSection = styled.div`
    width: calc(${props => (props.full ? `100%` : `50%`)} - 10px);
    margin: 5px;
    display: inline-block;
`;

export const StatusWrapper = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: rgb(95, 135, 31);
`;

export const Field = styled.input`
    height: 100%;
    border: 1px solid ${props => props.borderColor};
    padding: 3px;
    display: inline-block;
    font-size: 1.1em;
`;

export const Submit = styled.button`
    height: calc(100% + 8px);
    display: inline-block;
    border: 1px solid ${theme.color('darkgreen')};
    padding: 3px;
    background-color: ${theme.color('darkgreen')};
    color: white;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: hsl(83.10000000000002,63.6%,32.4%);
        border: 1px solid hsl(83.10000000000002,63.6%,32.4%);
    }
`;

export const FieldWrapper = styled.div`
    height: 25px;
    margin-top: 5px;
    display: flex;
    align-items: center;
`;
