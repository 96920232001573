import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
	margin: 0 auto;
	width: calc(100% - 20px);
	display: flex;
	padding-bottom: 20px;
`;

export const ImageArea = styled.div`
	width: 90%;
	display: flex;
`;

export const ImageWrapper = styled.div`
	padding: 5px;
	height: 100%;
	width: 100%;

	& .loader-container {
		height: 100%;
	}
`;

export const Image = styled.img`
	width: 100%;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	min-height: 100%;
`;

export const Arrow = styled.div`
	min-width: 80px;
	display: flex;
	align-items: center;
	font-size: 2em;
	color: lightgrey;

	& svg {
		margin: 0 auto;
		cursor: pointer;
		color: lightgrey;
		transition: 0.5s;
		transform: scale(1);
	}

	& svg:hover {
		color: grey;
		transform: scale(1.2);
	}
`;

export const ProgressWrapper = styled.div`
	width: 90%;
	max-width: 500px;
	background-color: lightgrey;
	display: flex;
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 8px;
	height: 8px;
`;

export const ProgressInnerLeft = styled.div`
	width: ${props => props.width}%;
	transition: width 0.5s;
	height: 100%;
`;

export const ProgressInnerMiddle = styled.div`
	width: ${props => props.width}%;
	transition: width 0.5s;
	height: 100%;
	background-color: darkgrey;
	border-radius: 8px;
`;

export const Loading = styled(theme.animation.div)`
	width: 100%;
	background-color: grey;
	/* border: 1px solid grey; */
	height: 100%;
	display: flex;
	align-items: center;
`;
