import React, { Component } from 'react';
import Axios from 'axios';
import {
	Top,
	Content,
	Title,
	Tagline,
	ContentContainer,
	NavContainer,
	NavBar,
	NavItem,
	BlendedStripe,
	Video,
	Outer,
    VideoCredit,
    NewsWrapper,
} from './components';
import { Wrapper as AboutWrapper, TileContent, Title as AboutTitle } from '../AboutTheProject/components';
import { Stripe } from '../../components/general';
import Partners from '../../components/Partners';
import { bg2 } from '../../assets';
import ImageStack, { Text } from '../../components/ImageStack';
import StatTrack, { Item } from '../../components/StatTrack';
import Footer from '../../components/Footer';
import { tweets, stripes, media, partnerText } from '../../data.json';
import TweetCycler from '../../components/Tweet/cycler';
import { formatHTML } from '../../utils/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import ContentLoop from '../../components/ContentLoop';


export default class Home extends Component {
	state = {
		top: null,
		loading: true,
		data: null,
	}

	async fetchData() {
		const [res, res2, res3] = await Promise.all([
			Axios.get('homeData'),
			Axios.get('totalHours'),
			Axios.get('totalRecordings')
		]);
		if (res
			&& res.status === 200
			&& res.data
			&& res2
			&& res2.status === 200
			&& res2.data
			&& res3
			&& res3.status === 200
			&& res3.data
			) {
			this.setState({ loading: false, error: false, data: {
				arbimon: res.data,
				totals: res2.data,
				recordings: res3.data,
			}});
		} else {
			this.setState({ loading: false, error: true, data: null });
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
        const { text } = partnerText.find((partner) => partner.title === 'Video');
		return (
			<Outer>
				<Top>
					<Video>
						<ContentContainer>
							<Content>
								<Title>Soundscapes to Landscapes</Title>
								<Tagline>A science-based project that seeks to advance animal biodiversity monitoring by making use of Earth-observing satellites. </Tagline>
								<NavContainer>
									<NavBar>
										<NavItem to="/volunteers">Volunteers</NavItem>
										<NavItem to="/about">About the Project</NavItem>
										<NavItem to="/thescience">The Science</NavItem>
									</NavBar>
								</NavContainer>
							</Content>
						</ContentContainer>
						{/* <Overlay /> */}
						<video autoPlay muted loop playsInline>
							<source src={media['header-video']} type="video/mp4" width="100%"/>
							<source src={media['header-video-ogg']} type="video/ogg" width="100%"/>
							<source src={media['header-video-webm']} type="video/webm" width="100%"/>
						</video>
					</Video>
					{/* <Gradient /> */}
				</Top>
				{
					// In the case all home page content is deleted in contentful, 
					// the stripe should not show up at all
					stripes.bypage.home && stripes.bypage.home.length > 0 ? (
						<React.Fragment>
							{/* <TreeArea /> */}
							<BlendedStripe image={bg2}>
								{stripes.bypage.home[0].media
									? (
										<ImageStack
											src={stripes.bypage.home[0].media.url}
											alt={stripes.bypage.home[0].media.description}
										/>
									) : null}
								<Text light tall>
									{formatHTML(stripes.bypage.home[0].markup)}
								</Text>
							</BlendedStripe>
						</React.Fragment>
					) : null
				}
				<Stripe short color="white">
					<TweetCycler tweets={tweets}/>
				</Stripe>
				<BlendedStripe color="darkgreen" image={bg2}>
					<StatTrack>
						{
							!this.state.loading && this.state.data ? (
								<>
									<Item
										value={this.state.data.totals.hours ? this.state.data.totals.hours.toLocaleString() : '--'}
										label="Citized Science Hours Completed"
									/>
									<Item
										value={this.state.data.recordings.totalSites ? this.state.data.recordings.totalSites.toLocaleString() : '--'}
										label="Unique Sites Visited"
									/>
									<Item
										value={this.state.data.recordings.totalMinutes ? this.state.data.recordings.totalMinutes.toLocaleString() : '--'}
										label="Minutes of Recordings"
									/>
									<Item
										value={this.state.data.arbimon.validations ? this.state.data.arbimon.validations.toLocaleString() : '--'}
										label="Bird Call Validations"
									/>
									<Item
										value={this.state.data.arbimon.modelCount ? this.state.data.arbimon.modelCount.toLocaleString() : '--'}
										label="Bird Species Modeled"
									/>
								</>
							) : (
								<>
									<Item
										value={<FontAwesomeIcon icon={faSpinner} spin/>}
										label="loading project stats"
									/>
								</>
							)
						}
					</StatTrack>
				</BlendedStripe>
				<ContentLoop content={stripes.bypage.home && stripes.bypage.home.length > 1 ? stripes.bypage.home.slice(1) : []} />
                <NewsWrapper>
                    <AboutWrapper simple fullwidth image={media.in_the_news} color={1} to="/about/news">
                        <TileContent>
                            <FontAwesomeIcon icon={faFileAlt} />
                            <AboutTitle>In the News</AboutTitle>
                        </TileContent>
                    </AboutWrapper>
                </NewsWrapper>
				<Partners />
                <VideoCredit>
                    {formatHTML(text)}
                </VideoCredit>
				<Footer />
			</Outer>
		);
	}
}
