import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Gallery from '../../components/Gallery';
import { stripes, galleries } from '../../data.json';
import ContentLoop from '../../components/ContentLoop';

export default class maps extends Component {
	render() {
		return (
			<React.Fragment>
				<Header title="Species Distribution Modeling" />
				<ContentLoop content={stripes.bypage.maps} />
				{
					// In the case all maps page content is deleted in contentful, 
					// the gallery should not show up at all
					galleries.bypage.maps && galleries.bypage.maps.length ? (
						galleries.bypage.maps.map((gallery, index) => {
							return <Gallery key={`gallery-maps-${index}`} data={gallery}/>
						})
					) : null
				}
				<Footer />
			</React.Fragment>
		);
	}
}