import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Stripe } from '../../components/general';
import { trees } from '../../assets';
import theme from '../../theme';
import { media } from '../../data.json';

export const Outer = styled.div`
	background: linear-gradient(rgba(220, 230, 136, 1), rgba(220, 230, 136, 0));
	z-index: 0;
`;

export const Overlay = styled.div`
	height: 60%;
	width: 100%;
	position: absolute;
	top: 40%;
	left: 0px;
	background: linear-gradient(rgba(220, 230, 136, 0), rgba(220, 230, 136, 1));
`;

// Main wrapper for top area
export const Top = styled.div`
	width: 100vw;
	position: relative;
	background-color(rgba(220, 230, 136, 0.8));
	background-size: cover;
	background-position: left top;
	display: flex;
	min-height: 590px;
	overflow: hidden;
	flex-direction: column;
	z-index: -1;
`;

export const Video = styled.div`
	width: 100%;
	flex-grow: 1;
	z-index: -1;
	background-image: url(${media['header-still']});
	background-color: white;
	overflow: hidden;
	position: inline;

	// & source {
	// 	width: 100%;
	// }

	& video {
		position: fixed;
		top: 0;
		background-color(rgba(220, 230, 136, 0.8));
		z-index: -1;
		min-width: 100%;
	}

	@media (max-width: 820px) {
		& video {
			display: none;
		}
		min-height: 590px;
		background-size: cover;
	}
`;

export const Gradient = styled.div`
	background: linear-gradient(rgba(220, 230, 136, 0), rgba(220, 230, 136, 1));
	flex-grow: 1;
	// background-color: red;
	width: 100%;
`;

export const ContentContainer = styled.div`
	width: 100%;
	margin: 80px 0 20px 0;
	z-index: 999;
`;

export const Content = styled.div`
	@keyframes slideIn {
		0% {
			transform: translateY(-200%);
		}
		100% {
			transform: translateY(0);
		}
	}

	width: 90%;
	background: rgba(197, 207, 129, 1);
	box-shadow: 0 2px 46px 0 rgba(0,0,0,0.50);
	margin: 0 auto;
	transform: translateY(-200%);
	animation: slideIn 1s forwards;
	padding: 10px 10px 0 10px;
`;

export const Title = styled.div`
	font-size: 2.5em;
	color: #000000;
	text-align: center;
	padding: 10px;
`;

export const Tagline = styled.div`
	color: #000000;
	text-align: center;
	padding: 10px;
	width: 80%;
	margin: 0 auto;
	font-size: 1.3em;
`;

export const NavContainer = styled.div`
	display: flex;
	width: 100%;
	margin: 0 auto;
	padding-top: 15px;
	padding-bottom: 20px;
`;

export const NavBar = styled.div`
	margin: 0 auto;
	background-color: ${theme.color('darkgreen')};
	display: flex;

	@media (max-width: 840px) {
		flex-direction: column;
	}
`;

export const NavItem = styled(NavLink)`
	text-decoration: none;
	display: inline-block;
	color: white;
	padding: 10px;
	font-size: 1.2em;
	padding-bottom: 10px;
	box-sizing: border-box;
	text-align: center;

	border: 6px solid rgba(197, 207, 129, 1);
	@media (max-width: 840px) {
		border: 2px solid rgba(197, 207, 129, 1);
	}

	&:hover {
		background-color: ${theme.color('darkgreen', 0.5)};
		/* box-shadow: inset 0 0 2px ${theme.color('darkgreen')}; */
		color: white;
	}
`;

export const BlendedStripe = styled(Stripe)`
	background: linear-gradient(rgba(220, 230, 136, 0.8), rgba(220, 230, 136, 0.6), rgba(112,168,68,0.5)), url(${props => props.image});
`;

export const TreeArea = styled.div`
	height: 100px;
	background-image: url(${trees});
	background-size: 276px;
	background-position: bottom left;
	background-repeat: repeat-x;
	background-color: #79C5FE;
`;

export const VideoCredit = styled.div`
    width: 100%;
    background: white;
    text-align: center;
    padding-bottom: 10px;
    color: #555555;
`;

export const NewsWrapper = styled.div`
    background: white;
`;
