import styled from 'styled-components';
import theme from '../../theme';

export const HTMLButton = styled.button`
    background-color: ${props => (theme.color(props.color))};
    color: white;
	font-size: 1.5em;
    margin: 10px auto 80px auto;
    border: none;
    cursor: pointer;
    display: inline-block;
    padding: 5px 15px;

    &:hover {
        background-color: ${props => (theme.color(props.color, .5))};
    }
`;