import styled from 'styled-components';
import theme from '../../../theme';
import { NavLink } from 'react-router-dom'

export const Wrapper = styled.div`
	margin: 10px 10px 10px auto;
	background-color: ${theme.color('lightBlue')};
	display: flex;

	@media (max-width: 840px) {
		flex-direction: column;
		margin: 10px auto;
	}
`;

export const Item = styled(NavLink)`
	text-decoration: none;
	display: inline-block;
	color: white;
	padding: 10px;
	font-size: 1.2em;
	text-align: center;

	border: 4px solid white;
	box-sizing: border-box;

	&:hover {
		background-color: ${theme.color('lightBlue', -0.5)};
	}

	&.active {
		background-color: ${theme.color('darkBlue')};

		&:hover {
			background-color: ${theme.color('darkBlue')};
		}
	}
`;