import React from 'react';
import { Wrapper, Image, Content, Handle, Body, Retweet, CreatedTime, Media, MediaWrapper, MediaBackground } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export default (props) => {
	const { tweet } = props;
	const { text, poster_url, retweet, retweeter, screen_name, media, created_at } = tweet;
	return (
		<Wrapper>
			<Image src={poster_url.replace(/_normal/g, '')} />
			<Content>
				<Handle><FontAwesomeIcon icon={faTwitter} />{' '}@{screen_name}</Handle>
				{retweet ? <Retweet><FontAwesomeIcon icon={faRetweet} />{' '}retweeted by @{retweeter}</Retweet> : null}
				<Body>
					<span dangerouslySetInnerHTML={{ __html: text }} />
				</Body>
				<CreatedTime>{moment(created_at, 'ddd MMM D HH:mm:ss ZZ YYYY').fromNow()}</CreatedTime>
			</Content>
			{media ? (
				<MediaWrapper href={media} target="_blank" rel="noopener noreferrer">
					<MediaBackground className="background">
						<Media src={media} alt="twitter meda" className="image"/> 
					</MediaBackground>
				</MediaWrapper>
			) : null}
		</Wrapper>
	);
}