import React from 'react';
import ImageLoader from 'react-load-image';
import { ImgWrapper, ImgBackdrop, Img, TextWrapper, Loading, Description } from './components';

export default (props) => props.embed ? (
	<ImgWrapper invert={props.invert} forceWidth={props.forceWidth} forceHeight={props.forceHeight}>
		<ImgBackdrop invert={props.invert}>
			<iframe
				className={props.invert ? 'invert' : ''}
				width="100%"
				height="315px%"
				src={props.embed}
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="S2L video"
			/>
		</ImgBackdrop>
	</ImgWrapper>
	) : (
	<ImgWrapper invert={props.invert} forceWidth={props.forceWidth} forceHeight={props.forceHeight} fullWidth={props.fullWidth}>
        <ImgBackdrop invert={props.invert}>
			<ImageLoader src={props.src}>
				<Img invert={props.invert}/>
				<Loading invert={props.invert}/>
				<Loading invert={props.invert}/>
			</ImageLoader>
		</ImgBackdrop>
        {props.alt && (
            <Description>{props.alt}</Description>
        )}
	</ImgWrapper>
);

export const Text = (props) => (
	<TextWrapper light={props.light} image={props.image} tall={props.tall}>
		{props.children}
	</TextWrapper>
);
