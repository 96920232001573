import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import { ShowMoreText } from '../../components/general';
import { mailchimp, media } from '../../data.json';
import { getMetadata } from '../../utils/content';
import Publication from '../../components/Publication';
import Button from '../../components/Button';
import { SectionTitle } from './components';

export default class InTheNews extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mailchimpOpen: false,
		};
	}
	render() {
		const { mailchimpOpen } = this.state;
		return (
			<React.Fragment>
				<Header title="In The News" />
				<Button
					color={'darkgreen'}
					label={'Sign Up for the Newsletter'}
					fontSize={1.15}
					onClick={() => {
						window.open(getMetadata('mailchimp_signup'), '_blank');
					}}
				/>
				<SectionTitle>Past Newsletters</SectionTitle> 
				{
					mailchimp.slice(0, mailchimpOpen ? mailchimp.length : 6).map((article, index) => {
						return (
							<Publication
								article={article}
								key={`article-${article.url}-${index}`}
								imageOverride={media.mailchimp_default}
							/>
						);
					})
				}
				{
					mailchimp.length && mailchimp.length > 6 ? (
						<ShowMoreText mailchimpOpen={mailchimpOpen}>
							<span onClick={() => this.setState((prevState) => {
								return { mailchimpOpen: !prevState.mailchimpOpen };
							})}>
								show {mailchimpOpen ? 'less' : 'more'}
								<br />
								<FontAwesomeIcon icon={faChevronDown} />
							</span>
						</ShowMoreText>
					) : null
				}
				<br/>
				<br />
			</React.Fragment>
		);
	}
}