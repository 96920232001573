import React from 'react';
import { Wrapper, Link, Img, Title, RecognitionText } from './components';
import { partners, partnerText } from '../../data.json';
import { formatHTML } from '../../utils/content';

const Partner = (props) => (
	<Link href={props.href} target="_blank" rel="noopener noreferrer">
		<Img src={props.src} />
	</Link>
)

export default (props) => {
    const { text } = partnerText.find((partner) => partner.title === 'NASA');
    return (
        <div style={{ backgroundColor: 'white' }}>
            {
                props.showHeader ? <Title>Project Partners</Title> : null
            }
            <Wrapper>
                {
                    partners ? partners.map((partner) => (
                        <Partner
                            key={`${partner.image}-${partner.name}`}
                            src={partner.image}
                            href={partner.link}
                        />
                    )) : null
                }
            </Wrapper>
            <RecognitionText>
            {formatHTML(text)}
            </RecognitionText>
            <br />
        </div>
    );
}