import styled, { css } from 'styled-components';
import colors from "./colors";

const keyFrameAnimation = css`
	@keyframes LoadingGradient {
		0% {
			background-position: 0% 50%
		}
		50% {
			background-position: 100% 50%
		}
		100% {
			background-position: 0% 50%
		}
	}
`;

export default {
	div: styled.div`
		${keyFrameAnimation}
		background: linear-gradient(-45deg, ${colors('darkBlue')}, ${colors('lightBlue')}, ${colors('lightGreen')}, ${colors('darkGreen')});
		background-size: 400% 400%;
		animation: LoadingGradient 10s ease infinite;
	`,
	span: styled.span`
		${keyFrameAnimation}
		background: linear-gradient(-45deg, ${colors('darkBlue')}, ${colors('lightBlue')}, ${colors('lightGreen')}, ${colors('darkGreen')});
		background-size: 400% 400%;
		animation: LoadingGradient 10s ease infinite;
	`,
	css: css`
		${keyFrameAnimation}
		background: linear-gradient(-45deg, ${colors('darkBlue')}, ${colors('lightBlue')}, ${colors('lightGreen')}, ${colors('darkGreen')});
		background-size: 400% 400%;
		animation: LoadingGradient 10s ease infinite;
	`,
}