import React from 'react';
import Navigation from './Navigation';
import { HeaderImage, HeaderText } from './components';

export default (props) => {
	return (
		<React.Fragment>
			<HeaderImage>
				<HeaderText>{props.title}</HeaderText>
			</HeaderImage>
			<Navigation />
		</React.Fragment>
	);
}