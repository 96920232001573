import styled from 'styled-components';

export const ShowMoreText = styled.div`
	text-align: center;
	font-weight: 200;
	font-size: 0.8em;
	min-height: 40px;

	& > span {
		transform: Scale(0);
		cursor: pointer;
		transition: 0.25s;
	}

	& span:hover {
		font-size: 1.1em;
		color: #172E15;
	}

	& svg {
		transition: transform 0.5s;
		${props => props.articlesOpen ? `
			transform: rotate(180deg);
		` : `
			transform: rotate(0deg);
		`}
	}
`;

export const Map = styled.iframe`
	width: calc(100% - 20px);
	flex: 1;
	height: 100%;
	min-height: 80vh;
	padding: 10px;
	border: none;
`;
