import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
	max-width: 350px;
	width: 100%;
	padding: 20px;
	height: 100%;
`;

export const Image = styled.img`
	width: 50%;
	border-radius: 100%;
	padding-bottom: 10px;
`;

export const Text = styled.div`
	font-size: 1.1em;
	line-height: 1.4em;
	height: 100%;
`;

export const Title = styled.div`
	font-size: 1.3em;
	font-weight: 500;
	padding-bottom: 6px;
`;

export const ViewMoreLink = styled.span`
	color: ${theme.color('darkBlue')};
	cursor: pointer;
	border: 1px solid ${theme.color('lightBlue')};
	padding: 3px 7px 5px 7px;
	border-radius: 100px;
	display: inline-flex;
	margin-top: 10px;
	font-size: 0.8em;

	&:hover {
		color: ${theme.color('lightBlue')};
	}
`;
