import React from 'react';
import { Wrapper, Links } from './components';
import SocialLinks from '../SocialLinks';
import { getMetadata } from '../../utils/content';

export default () => (
	<Wrapper>
		<Links>
			<a href={`mailto:${getMetadata('contact_email')}`}>Contact Us</a>
			<br />
			<a href={getMetadata('volunteer_signup')} target="_blank" rel="noopener noreferrer">Sign up to Volunteer</a>
			<br />
			<a href={getMetadata('newsletter')} target="_blank" rel="noopener noreferrer">Join the Newsletter</a>
		</Links>
		<SocialLinks />
	</Wrapper>
)