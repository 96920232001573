import React, { Component } from 'react';
import ImageLoader from 'react-load-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Wrapper, ImageArea, ImageWrapper, Image, Arrow, ProgressWrapper, ProgressInnerLeft, ProgressInnerMiddle, Loading } from './components';

export default class Gallery extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lastImg: Math.min(props.data.images.length - 1, 2),
			firstImg: 0,
		}
	}

	moveRight() {
		this.setState((prevState) => {
			return (prevState.lastImg + 1) <= this.props.data.images.length - 1 ? {
				lastImg: prevState.lastImg + 1,
				firstImg: prevState.firstImg + 1,
			} : {};
		});
	}

	moveLeft() {
		this.setState((prevState) => {
			return (prevState.firstImg - 1) >= 0 ? {
				lastImg: prevState.lastImg - 1,
				firstImg: prevState.firstImg - 1,
			} : {};
		});
	}

	render() {
		const { data, showImageTitles = false } = this.props;
		const { firstImg, lastImg } = this.state;
		const { images } = data;
		return (
			<React.Fragment>
				<Wrapper>
					<Arrow>
						<FontAwesomeIcon icon={faChevronLeft} onClick={() => this.moveLeft()}/>
					</Arrow>
					<ImageArea>
						{
							images.slice(firstImg, lastImg + 1).map((image, i) => {
								return (
									<ImageWrapper key={image.url}>
										<ImageLoader src={image.url} className="loader-container">
											<Image />
											<Loading />
											<Loading />
										</ImageLoader>
										{showImageTitles && image.title}
									</ImageWrapper>
								)
							})
						}
					</ImageArea>
					<Arrow>
						<FontAwesomeIcon icon={faChevronRight} onClick={() => this.moveRight()}/>
					</Arrow>
				</Wrapper>
				<ProgressWrapper>
					<ProgressInnerLeft width={firstImg / images.length * 100}/>
					<ProgressInnerMiddle width={((lastImg - firstImg) + 1) / images.length * 100}/>
				</ProgressWrapper>
			</React.Fragment>
		);
	}
}