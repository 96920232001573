import React from 'react';
import { Wrapper, ItemWrapper, Value, Label } from './components';

export default (props) => (
	<Wrapper>
		{props.children}
	</Wrapper>
);

export const Item = (props) => (
	<ItemWrapper>
		<Value>{props.value}</Value>
		<Label>{props.label}</Label>
	</ItemWrapper>
);
