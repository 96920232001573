import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Publication from '../../components/Publication';
import { stripes, articles } from '../../data.json';
import { Map, ShowMoreText } from './components';
import ContentLoop from '../../components/ContentLoop';


export default class TheScience extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			articlesOpen: false,
		};
	}
	render() {
		const { articlesOpen } = this.state;

		return (
			<React.Fragment>
				<Header title="The Science"/>
				{
					articles.slice(0, articlesOpen ? articles.length : 2).map((article, index) => {
						return (
							<Publication article={article} key={`article-${article.url}-${index}`}/>
						);
					})
				}
				{
					articles.length && articles.length > 2 ? (
						<ShowMoreText articlesOpen={articlesOpen}>
							<span onClick={() => this.setState((prevState) => {
								return { articlesOpen: !prevState.articlesOpen };
							})}>
								show {articlesOpen ? 'less' : 'more'}
								<br />
								<FontAwesomeIcon icon={faChevronDown} />
							</span>
						</ShowMoreText>
					) : null
				}
				<ContentLoop content={stripes.bypage.thescience.slice(0, 4)} />
				<Map
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0" 
					src="https://sonoma.maps.arcgis.com/apps/webappviewer/index.html?id=de0fe707f8964da3bbb127cc23576054">
				</Map>
				<ContentLoop content={stripes.bypage.thescience.slice(4)} />
				<Footer />
			</React.Fragment>
		);
	}
}
