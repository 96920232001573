import React, { Component } from 'react';
import { CyclerWrapper, Arrow, CyclerContent, Positions, Dots, Dot } from './components';
import Tweet from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const cycleDelayMs = 10000;

export default class TweetCycler extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			index: 0,
		};

		this.moveLeft = this.moveLeft.bind(this);
		this.moveRight = this.moveRight.bind(this);
	}

	moveLeft(click = false) {
		if (click) {
			window.clearInterval(this._interval);
		}
		this.setState((prevState) => {
			const newIndex = prevState.index - 1;
			if (newIndex < 0) {
				return { index: this.props.tweets.length - 1 };
			}
			return { index: newIndex };
		});
		if (click) {
			this._interval = window.setInterval(this.moveRight, cycleDelayMs);
		}
	}

	moveRight(click = false) {
		if (click) {
			window.clearInterval(this._interval);
		}
		this.setState((prevState) => {
			const newIndex = prevState.index + 1;
			return { index: newIndex % this.props.tweets.length };
		});
		if (click) {
			this._interval = window.setInterval(this.moveRight, cycleDelayMs);
		}
	}

	componentDidMount() {
		this._interval = window.setInterval(this.moveRight, cycleDelayMs);
	}

	componentWillUnmount() {
		window.clearInterval(this._interval);
	}

	render() {
		const { tweets } = this.props;
		return (
			<CyclerWrapper>
				<Arrow>
					<FontAwesomeIcon icon={faChevronLeft} onClick={() => this.moveLeft(true)}/>
				</Arrow>
				<CyclerContent>
					<Tweet tweet={tweets[this.state.index]} />
					<Positions>
						<Dots>
							{
								this.props.tweets.map((tweet, index) => {
									return (
										<Dot
											active={index === this.state.index}
											onClick={async () => {
												window.clearInterval(this._interval);
												await this.setState({ index });
												this._interval = window.setInterval(this.moveRight, cycleDelayMs);
											}}
											key={`tweet-${index}`}
										/>
									);
								})
							}
						</Dots>
					</Positions>
				</CyclerContent>
				<Arrow>
					<FontAwesomeIcon icon={faChevronRight} onClick={() => this.moveRight(true)}/>
				</Arrow>
			</CyclerWrapper>
		);
	}
}