import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import Home from './screens/Home';
import AboutTheProject from './screens/AboutTheProject';
import Volunteers from './screens/Volunteers';
import TheScience from './screens/TheScience';
import { PageWrapper } from './components/general';
import ProjectOverview from './screens/ProjectOverview';
import Mission from './screens/Mission';
import WhyBiodiversity from './screens/WhyBiodiversity';
import SoundscapesAndBioacoustical from './screens/SoundscapesAndBioacoustical';
import SpeciesDistribution from './screens/SpeciesDistribution';
import OurTeam from './screens/OurTeam';
import InTheNews from './screens/InTheNews';
import TrainingMaterials from './screens/TrainingMaterials';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const AlwaysScroll = withRouter(ScrollToTop);

class App extends Component {
  render() {
    return (
      <Router>
        <AlwaysScroll>
          <PageWrapper>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={AboutTheProject} />
              <Route exact path="/volunteers" component={Volunteers} />
              <Route exact path="/volunteers/materials" component={TrainingMaterials} />
              <Route exact path="/thescience" component={TheScience} />

              <Route exact path="/about/overview" component={ProjectOverview} />
              <Route exact path="/about/mission" component={Mission} />
              <Route exact path="/about/biodiversity" component={WhyBiodiversity} />
              <Route exact path="/about/soundscapes" component={SoundscapesAndBioacoustical} />
              <Route exact path="/about/sdm" component={SpeciesDistribution} />
              <Route exact path="/about/team" component={OurTeam} />
              <Route exact path="/about/news" component={InTheNews} />
          </PageWrapper>
        </AlwaysScroll>
      </Router>
    );
  }
}

export default App;
