import React, { Component } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../theme';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';

export const Filler = styled.div`
	height: ${props => 20 * (props.rows || 1)}px;
`;

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

class _PageWrapper extends Component {
	render() {
		const { location, children } = this.props;
		return (
			<Wrapper>
				<Switch location={location}>
					{children}
				</Switch>
			</Wrapper>
		)
	}
}

export const PageWrapper = withRouter(_PageWrapper);

export const Tile = styled.div`
	box-shadow: ${theme.defaults.shadow};
	width: calc(100% - 40px);
	max-width: 1400px;
	margin: 0 auto;
	margin-top: 10px;
	padding: 10px;
`;

export const Stripe = styled.div`
	min-height: ${props => (props.short ? 200 : 300)}px;
	background: url(${props => props.image});
	background-color: ${props => props.color || '#B0C694'};
	background-size: cover;
	display: flex;
	align-items: center;
	background-attachment: fixed;
	background-repeat: no-repeat;

	@media (max-width: 920px) {
		flex-direction: column;
	}
`;

export const ShowMoreText = styled.div`
	text-align: center;
	font-weight: 200;
	font-size: 0.8em;
	min-height: 40px;

	& > span {
		transform: Scale(0);
		cursor: pointer;
		transition: 0.25s;
	}

	& span:hover {
		font-size: 1.1em;
		color: #172E15;
	}

	& svg {
		transition: transform 0.5s;
		${props => props.articlesOpen ? `
			transform: rotate(180deg);
		` : `
			transform: rotate(0deg);
		`}
	}
`;

