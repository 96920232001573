import styled from 'styled-components';

export const Wrapper = styled.div`
	max-width: 100%;
	display: flex;
	text-align: center;
	flex-wrap: wrap;
	justify-content: center;
`;

export const Link = styled.a`
	margin: 5px;
	height: 50px;
`;

export const Img = styled.img`
	height: 100%;

	@media (max-width: 840px) {
		height: 80%;
	}
`;

export const Title = styled.div`
	width: calc(100% - 20px);
	text-align: center;
	font-size: 1.5em;
	padding: 10px;
`;

export const RecognitionText = styled.div`
	text-align: center;
	padding-top: 10px;
`;