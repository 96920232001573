import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Wrapper } from './components';
import { getMetadata } from '../../utils/content';

export default (props) => (
	<Wrapper>
		<a href={getMetadata('twitter')} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitterSquare} /></a>
		{' '}
		<a href={getMetadata('instagram')} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
		{' '}
		<a href={getMetadata('facebook')} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a>
		{' '}
		<a href={`mailto:${getMetadata('contact_email')}`}><FontAwesomeIcon icon={faEnvelope} /></a>
	</Wrapper>
)