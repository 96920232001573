import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	text-align: center;
	flex-wrap: wrap;
	justify-content: center;
	padding: 20px;
`;

export const Paragraph = styled.div`
	max-width: 900px;
	margin: 0 auto;
	padding: 20px;
	font-size: 1.4em;
	color: #172E15;
	text-align: center;
`;
