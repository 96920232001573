import styled from 'styled-components';
import theme from '../../theme';

export const MaterialWrapper = styled.div`
	background: #FFFFFF;
	display: flex;
	max-width: 1000px;
	width: 90%;
	margin: 10px auto;
    text-decoration: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    padding: 10px;
    align-items: center;

    &:last-child {
        margin-bottom: 60px;
    }
`;

export const MaterialContent = styled.div`
    & strong {
        display: inline-block;
        padding-bottom: 7px;
    }
`;

export const MaterialButtons = styled.div`
    font-size: 1.4em;
    margin-left: auto;
    padding-left: 20px;
    display: flex;

    & svg {
        padding: 5px;
    }

    & svg:hover {
        color: ${theme.color('darkgreen')};
        cursor: pointer;
    }

    & a {
        color: black;
    }
`;

export const MaterialHeader = styled.div`
    max-width: 1000px;
    width: 90%;
    margin: 10px auto; 
    font-size: 1.3em;
    font-weight: 500;
    border-bottom: 6px solid ${theme.color('darkgreen')};

    &:not(:first-child) {
        padding-top: 20px;
    }

    & svg {
        margin-right: 10px;
        font-size: 1.8em;
        color: ${theme.color('darkgreen')};
    }
`;
