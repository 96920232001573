import styled from 'styled-components';
import theme from '../../theme';
import { media } from '../../data.json';

export const HeaderImage = styled.div`
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;
		background: url(${media['header-still']});
		background-size: cover;
		background-position: left top;
		background-repeat: no-repeat;
		z-index: -1;
		filter: brightness(0.9);
	}
`;

export const HeaderText = styled.div`
	font-size: 3em;
	color: ${theme.text('light')};
	margin-top: auto;
	padding-left: 10px;
`;