import Color from 'color';

const initial = 'white';

const mappings = {
	lightblue: '#78BDFE',
	darkblue: '#003C81',
	lightgreen: '#D2E7A2',
	darkgreen: '#3F5A14',
	orange: '#FFD166',
	lightorange: '#FBD1A2',
};

export default (color, lighten = null) => {
	const result = new Color(mappings[color.toLowerCase()] || initial);
	if (lighten === null) {
		return result.hsl().string();
	} else if (lighten >= 0) {
		return result.lighten(lighten).hsl().string();
	} else if (lighten < 0) {
		return result.darken(lighten * -1).hsl().string();
	}
	return result.hsl().string();
}