import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	width: calc(100% - 20px);
	background-color: rgba(0, 0, 0, 0.5);
	padding: 10px;

	@media (max-width: 680px) {
		flex-direction: column;
	}
`;

export const ItemWrapper = styled.div`
	width: calc(100% - 20px);
	text-align: center;
	padding: 10px;
`;

export const Value = styled.div`
	font-size: 3.5em;
	color: #78BDFE;
	text-align: center;
`;

export const Label = styled.div`
	font-size: 1.1em;
	color: #FFFFFF;
	text-align: center;
`;
