import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	width: calc(100% - 20px);
	max-width: 1000px;
	margin: 0 auto;
	padding: 10px;

	@media (max-width: 840px) {
		flex-direction: column;
		padding: 0;
	}
`;

export const SpotlightWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    width: 100%;
    align-items: center;
    @media (max-width: 920px) {
        flex-direction: column;
    }
`;

export const SpotlightImageWrapper = styled.div`
    min-width: 20%;
    max-width: 20%;
    padding: 20px;
    @media (max-width: 920px) {
        min-width: 60%;
        max-width: 60%;
    }
`;

export const SpotlightImage = styled.img`
    width: 100%;
    border-radius: 400px;
`;

export const SpotlightContent = styled.div`
    width: calc(80% - 80px);
    padding-left: 20px;
    @media (max-width: 920px) {
        width: 100%;
        text-align: center;
        padding-left: 0;
    }
`;

export const FullName = styled.div`
    font-size: 1.5em;
    font-weight: 200;
`;

export const Dates = styled.div`
    color: grey;
`;

export const Description = styled.div`
    font-size: 1.2em;
`;

export const CyclerContent = styled.div`
	width: calc(100% - 40px);
	padding: 20px;
`;

export const Arrow = styled.div`
	min-width: 40px;
	display: flex;
	align-items: center;
	font-size: 2em;
	color: lightgrey;

	& svg {
		margin: 0 auto;
		cursor: pointer;
		color: lightgrey;
		transition: 0.5s;
		transform: scale(1);
	}

	& svg:hover {
		color: grey;
		transform: scale(1.2);
	}

	@media (max-width: 840px) {
		display: none;
	}
`;
