import React from 'react';
import { Wrapper, Item } from './components';

export default () => {
	return (
		<Wrapper>
			<Item exact to="/">Home</Item>
			<Item to="/volunteers">Volunteers</Item>
			<Item to="/about">About the Project</Item>
			<Item to="/thescience">The Science</Item>
		</Wrapper>
	);
}
