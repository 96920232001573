import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import { MaterialWrapper, MaterialHeader, MaterialContent, MaterialButtons } from './components';
import { trainingMaterials } from '../../data.json';
import { formatHTML } from '../../utils/content';

export default class TrainingMaterials extends Component {
	render() {
		const { trainingSections, trainingBySection } = trainingMaterials;
		return (
			<React.Fragment>
				<Header title="Training Materials" />
				{
					trainingSections.map((section) => {
						return (
							<React.Fragment key={`section-${section}`}>
								<MaterialHeader>
									{trainingBySection[section][0] && trainingBySection[section][0].icon && (
										<FontAwesomeIcon icon={icons[`fa${trainingBySection[section][0].icon.charAt(0).toUpperCase()}${trainingBySection[section][0].icon.slice(1)}`]} />
									)}{section}	
								</MaterialHeader>
								{
									trainingBySection[section].map((material) => {
										return (
											<MaterialWrapper key={`${material.url}-${material.title}`}>
												<MaterialContent>
													<strong>{material.title}</strong>
													<br />
													{formatHTML(material.markup)}
												</MaterialContent>
												<MaterialButtons>
													<a href={material.url} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={icons.faFileDownload} /></a>
												</MaterialButtons>
											</MaterialWrapper>
										)
									})
								}
							</React.Fragment>
						)
					})
				}
			</React.Fragment>
		);
	}
}