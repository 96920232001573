import styled from 'styled-components';

export const Wrapper = styled.div`
	text-align: center;
	font-size: 2em;
	color: #3F5A14;
	padding: 5px;

	& a {
		color: #3F5A14;
	}
`;
