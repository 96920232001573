import React, { Component } from 'react';
import Header from '../../components/Header';
import { bios } from '../../data.json';
import { Container, Paragraph } from './components';
import Bio from '../../components/Bio';
import { formatHTML } from '../../utils/content';

export default class OurTeam extends Component {
	render() {
		const firstIsTitle = bios.slice(0, 1)[0].order === -100;
		return (
			<React.Fragment>
				<Header title="Our Team" />
				{
						bios && bios.length && bios.length && firstIsTitle ? bios.slice(0, 1).map((single) => {
							return (
								<Paragraph key={`${single.fullName}-${single.order}`}>
									{formatHTML(single.markup)}
								</Paragraph>
							)
						}) : null
					}
				<Container>
					{
						bios && bios.length && bios.length > (firstIsTitle ? 1 : 0) && bios.slice(firstIsTitle ? 1 : 0).map((single) => {
							return (
								<React.Fragment key={`${single.fullName}-${single.order}`}>
									<Bio data={single} />
								</React.Fragment>
							)
						})
					}
				</Container>
			</React.Fragment>
		);
	}
}